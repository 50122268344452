import type { MenuItemConfig, SubmenuItemConfig } from 'handsontable/plugins/contextMenu'
import { CustomPropertyPlugin } from './core'

const setHeadingTypeColumnCommand = (core: CustomPropertyPlugin): SubmenuItemConfig => {
  return {
    key: 'headingType:column',
    name: '表頭に設定',
    callback: () => {
      const customProperties = core
        .findOrCreateCustomProperties(core.selectedCells())
        .filter((customProperty) => customProperty.headingType !== 'column')
      if (customProperties.length === 0) return

      for (const customProperty of customProperties) {
        customProperty.headingType = 'column'
      }
      core.runHooksAndRender(CustomPropertyPlugin.SET_CUSTOM_PROPERTY_EVENT)
    },
  }
}

const setHeadingTypeLineCommand = (core: CustomPropertyPlugin): SubmenuItemConfig => {
  return {
    key: 'headingType:line',
    name: '表側に設定',
    callback: () => {
      const customProperties = core
        .findOrCreateCustomProperties(core.selectedCells())
        .filter((customProperty) => customProperty.headingType !== 'line')
      if (customProperties.length === 0) return

      for (const customProperty of customProperties) {
        customProperty.headingType = 'line'
      }
      core.runHooksAndRender(CustomPropertyPlugin.SET_CUSTOM_PROPERTY_EVENT)
    },
  }
}

const removeHeadingTypeCommand = (core: CustomPropertyPlugin): SubmenuItemConfig => {
  return {
    key: 'headingType:remove',
    name: '解除',
    callback: () => {
      const customProperties = core
        .findCustomProperties(core.selectedCells())
        .filter((customProperty) => customProperty.headingType)
      if (customProperties.length === 0) return

      for (const customProperty of customProperties) {
        customProperty.headingType = undefined
      }
      core.runHooksAndRender(CustomPropertyPlugin.REMOVE_CUSTOM_PROPERTY_EVENT)
    },
    disabled: () => !core.selectedCells().some((cell) => core.findCustomProperty(cell)?.headingType),
  }
}

export const headingTypeCommands = (core: CustomPropertyPlugin): MenuItemConfig => {
  return {
    key: 'headingType',
    name: '見出し',
    submenu: {
      items: [setHeadingTypeColumnCommand(core), setHeadingTypeLineCommand(core), removeHeadingTypeCommand(core)],
    },
  }
}
