/* eslint-disable github/unescaped-html-literal */

const EXCLUSIVE_MARK = '<exclusive>排他</exclusive>'

export const CUSTOM_TAG_OPEN_PATTERN = /<(red|blue|green|bg-yellow|b|u|exclusive|unknown)>/g
export const CUSTOM_TAG_CLOSE_PATTERN = /<\/(red|blue|green|bg-yellow|b|u|exclusive|unknown)>/g

export const isEmpty = (value: string | undefined | null): boolean => {
  return value === undefined || value === null || value.trim() === ''
}

export const emptyToUndefined = (value: string | undefined): string | undefined => {
  return isEmpty(value) ? undefined : value
}

export const applyExclusiveMark = (value: string | undefined, isExclusive: boolean | undefined) => {
  const parts = []
  if (value) parts.push(value)
  if (isExclusive) parts.push(EXCLUSIVE_MARK)

  return parts.join(' ')
}
