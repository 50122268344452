import { useMergeRefs } from '@chakra-ui/hooks'
import { forwardRef, useRef, useState, type ForwardRefRenderFunction } from 'react'
import { Stack } from 'react-bootstrap'
import { StyledContentToolbar } from './StyledContentToolbar'
import { type BsAutosizeTextareaProps } from '@/src/components/Basic/BsAutosizeTextarea'
import { BsAutosizeTextarea } from '@/src/components/Basic/BsAutosizeTextarea'
import { useQuestionReferenceMap } from '@/src/hooks/useReferenceMaps'
import { convertReferenceIdForEdit, convertReferenceIdForSave } from '@/src/lib/referenceUtils'
import { wrapSelectionWithTag } from '@/src/lib/textareaUtils'

export type StyledContentEditorProps = Omit<BsAutosizeTextareaProps, 'value' | 'onChange' | 'onSubmit'> & {
  value: string
  containerClassName?: string
  enableBgColor?: boolean
  onChange?: (value: string) => void
}

const StyledContentEditorFn: ForwardRefRenderFunction<HTMLTextAreaElement, StyledContentEditorProps> = (
  { value: initialValue, containerClassName, enableBgColor = false, onChange, ...props },
  ref,
) => {
  const internalRef = useRef(null)
  const refs = useMergeRefs(internalRef, ref)
  const questionReferenceMap = useQuestionReferenceMap()
  const [value, setValue] = useState(() => convertReferenceIdForEdit(initialValue, questionReferenceMap))

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value)
    onChange?.(convertReferenceIdForSave(event.target.value, questionReferenceMap))
  }

  const handleClick = (tag: string) => {
    if (!internalRef?.current) return

    wrapSelectionWithTag(internalRef?.current, tag)
  }

  return (
    <Stack gap={1} className={containerClassName}>
      <BsAutosizeTextarea ref={refs} value={value} onChange={handleChange} {...props} />
      <StyledContentToolbar enableBgColor={enableBgColor} onClick={handleClick} />
    </Stack>
  )
}

export const StyledContentEditor = forwardRef(StyledContentEditorFn)
