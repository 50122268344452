type Tag = {
  name: string
  indexStart: number
  indexEnd: number
}

const parseTags = (value: string): Tag[] => {
  return [...value.matchAll(/<[^>]+?>/g)].map((match) => {
    return {
      name: match[0],
      indexStart: match.index,
      indexEnd: match.index + match[0].length,
    }
  })
}

const isOnTag = (tags: readonly Tag[], position: number): boolean => {
  return tags.some((tag) => tag.indexStart < position && position < tag.indexEnd)
}

export const wrapSelectionWithTag = (
  target: HTMLTextAreaElement,
  tagName: string,
): 'WRAPPED' | 'UNWRAPPED' | 'INVALID' => {
  const { value, selectionStart, selectionEnd } = target
  const tags = parseTags(value)
  target.focus()

  // タグの上にカーソル・選択範囲がある場合はどうやっても不正な編集になるので何もしない
  if (isOnTag(tags, selectionStart) || isOnTag(tags, selectionEnd)) return 'INVALID'

  const beforeCursorTag = [...tags].reverse().find((tag) => tag.indexEnd <= selectionStart)
  const afterCursorTag = tags.find((tag) => tag.indexStart >= selectionEnd)
  const isAlreadyWrapped = beforeCursorTag?.name === `<${tagName}>` && afterCursorTag?.name === `</${tagName}>`

  if (isAlreadyWrapped) {
    // すでにタグで囲まれているのでタグを削除する
    target.setRangeText(
      value.slice(beforeCursorTag.indexEnd, afterCursorTag.indexStart),
      beforeCursorTag.indexStart,
      afterCursorTag.indexEnd,
      'end',
    )
    target.dispatchEvent(new Event('change', { bubbles: true }))

    // もとの選択範囲を維持するために、削除したタグの文字数分だけずらしている
    const offset = tagName.length + 2
    target.setSelectionRange(selectionStart - offset, selectionEnd - offset)

    return 'UNWRAPPED'
  } else {
    // 対象の文字列をタグで囲む
    target.setRangeText(
      `<${tagName}>${value.slice(selectionStart, selectionEnd)}</${tagName}>`,
      selectionStart,
      selectionEnd,
      'end',
    )
    target.dispatchEvent(new Event('change', { bubbles: true }))

    // もとの選択範囲を維持するために、追加したタグの文字数分だけずらしている
    const offset = tagName.length + 2
    target.setSelectionRange(selectionStart + offset, selectionEnd + offset)

    return 'WRAPPED'
  }
}
