import type {
  CellReferenceForSurveyFragment as CellReference,
  QuestionReferenceForSurveyFragment as QuestionReference,
} from '@/src/graphql/generated'

export type {
  CellReferenceForSurveyFragment as CellReference,
  QuestionReferenceForSurveyFragment as QuestionReference,
} from '@/src/graphql/generated'

export type QuestionReferenceMap = Record<string, QuestionReference>
export type CellReferenceMap = Record<string, CellReference>
export type ParseReferenceIdReturn = {
  referenceId: string | undefined
  displayType: 'address' | 'value' | undefined
}

export const REFERENCE_ID_PATTERN = /#{([AB]?\d+)(?::([\da-z]{3})(?::(v))?)?}/
const SPLIT_BY_REFERENCE_ID_PATTERN = /(#{.*?})/

export const generateCellReferenceId = () => {
  return Math.floor(Math.random() * 46_655) // 36 ^ 3 - 1 (000 ~ zzz)
    .toString(36)
    .padStart(3, '0')
}

export const buildReferenceId = (questionUniqueId: string, cellReferenceId?: string, forValue = false): string => {
  if (cellReferenceId) {
    return `#{${questionUniqueId}:${cellReferenceId}${forValue ? ':v' : ''}}`
  }
  return `#{${questionUniqueId}}`
}

export const generateQuestionReferenceMap = (references: readonly QuestionReference[]): QuestionReferenceMap => {
  return Object.fromEntries(references.map((reference) => [reference.id, reference]))
}

export const generateCellReferenceMap = (references: readonly CellReference[]): CellReferenceMap => {
  return Object.fromEntries(references.map((reference) => [reference.id, reference]))
}

export const convertReferenceIdForEdit = (
  value: string | undefined,
  questionReferenceMap: QuestionReferenceMap,
): string => {
  if (!value) return ''

  let result = value
  for (const [_, ref] of Object.entries(questionReferenceMap)) {
    // パフォーマンスが気になるようになったらもう少し効率のよい実装に見直す
    const re = new RegExp(`${ref.id.slice(0, -1)}([:}])`, 'g')
    result = result.replace(re, `#{Q${ref.number}$1`) // #{A001} → #{Q1}, #{A001:xxx} → #{Q1:xxx}
  }
  return result.replaceAll(/\n+$/g, '')
}

export const convertReferenceIdForSave = (value: string, questionReferenceMap: QuestionReferenceMap): string => {
  if (!/#{Q\d+([:}])/g.test(value)) return value

  let result = value
  for (const [_, ref] of Object.entries(questionReferenceMap)) {
    const re = new RegExp(`#{Q${ref.number}([:}])`, 'g')
    result = result.replace(re, `${ref.id.slice(0, -1)}$1`) // #{Q1} → #{A001}, #{Q1:xxx} → #{A001:xxx}
  }

  // NOTE: 存在しないQ番号はエラーとなる参照ID(正規の参照IDはA001〜)に置きかえる
  return result.replaceAll(/#{Q\d+([:}])/g, '#{A000$1')
}

export const hasReferenceId = (value: string): boolean => {
  return SPLIT_BY_REFERENCE_ID_PATTERN.test(value)
}

export const parseReferenceId = (value: string): ParseReferenceIdReturn => {
  const match = value.match(REFERENCE_ID_PATTERN)
  if (!match) {
    return {
      referenceId: undefined,
      displayType: undefined,
    }
  }

  return {
    referenceId: buildReferenceId(match[1], match[2]),
    displayType: match[3] === 'v' ? 'value' : 'address',
  }
}
