import { Controller } from '@hotwired/stimulus'
import { isEmpty } from '@/src/lib/stringUtils'
import { wrapSelectionWithTag } from '@/src/lib/textareaUtils'

// eslint-disable-next-line import/no-default-export, unicorn/no-anonymous-default-export
export default class extends Controller {
  static targets = ['form', 'speaker', 'textarea', 'hardToHear']

  declare readonly formTarget: HTMLFormElement
  declare readonly speakerTargets: readonly HTMLInputElement[]
  declare readonly textareaTarget: HTMLTextAreaElement
  declare readonly hardToHearTarget: HTMLInputElement

  textareaTargetConnected = (element: HTMLTextAreaElement) => {
    element.addEventListener('keydown', this.onKeydown)
    element.focus()
  }

  focus = (): void => {
    console.debug('focus', this.textareaTarget)
    this.textareaTarget.focus()
  }

  insertHardToHearText = (): void => {
    switch (wrapSelectionWithTag(this.textareaTarget, 'unknown')) {
      case 'WRAPPED': {
        const { value, selectionStart, selectionEnd } = this.textareaTarget
        const selected = value.slice(selectionStart, selectionEnd)
        const insert = `${isEmpty(selected) ? '●●●●' : selected}(時間を入力)`
        this.textareaTarget.setRangeText(insert, selectionStart, selectionEnd)
        this.textareaTarget.setSelectionRange(selectionStart + insert.length - 6, selectionStart + insert.length - 1)
        this.textareaTarget.dispatchEvent(new Event('change', { bubbles: true }))
        break
      }
    }

    this.focus()
  }

  onKeydown = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === 'Enter') {
      event.preventDefault()
      this.formTarget.requestSubmit()
    }

    if ((event.ctrlKey || event.altKey) && event.key === '1') {
      event.preventDefault()
      this.speakerTargets[0].checked = true
    }
    if ((event.ctrlKey || event.altKey) && event.key === '2') {
      event.preventDefault()
      this.speakerTargets[1].checked = true
    }
  }
}
